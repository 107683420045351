.carregador {
    text-align: center;
    color: grey;
    position: absolute;
    top: 0;
    bottom: 0;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #3030308c;
    flex-direction: column;
    color: #222222;
    text-shadow: 1px 1px 6px #6c6c6c;
    color: #efefef;
    text-shadow: 1px 1px 6px #6c6c6c;
    font-weight: lighter;

    .icone {
        font-size: 40px;
        filter: drop-shadow(1px 1px 1px #6c6c6c);

        &.small{
            font-size: 25px;
        }
    }
}