.containerArquivo {

    .arquivoImagem {
        height: 60px;
        width: 100px;
        object-fit: contain;
    
    }
    
    .descricao {
        font-size: 0.7rem;
    }

    .barra {
        // height: 22px;
    }

    .botao {
        // font-weight: lighter;

        font-size: 0.75rem;

        span {
            font-size: 0.75rem;
        }
    }
}
