.formulario{
    input{
        border: 0;
        border-bottom: 1px solid #67615e;
        border-radius: 0;

        &:hover{    
            border-bottom: 1px solid #a0897f;
        }

        &:focus, &:active{    
            border-bottom: 1px solid #ffa03e;
        }

    }

    .select{
        
        .select__control{
            &:hover{
                border-bottom: 1px solid #8d7d76 !important;
            }

            &:active{
                border-bottom: 1px solid #ffa03e !important;
            }
        }

    }
}

.alerta{
    font-size: 12px;
    padding: 5px 10px !important;
    border-radius: 5px;

    button{
        padding: 4px 2px !important;
        font-size: 20px;
    }

    p{
        margin: 0;
    }
}