.login-pagina {

    // :root {
    --input-padding-x: 1.25rem;
    --input-padding-y: 0.5rem;
    // }
    
    .login,
    .image {
        min-height: 100vh;
    }

	.logo{
		position: absolute;
		top: 0;
		left: 0;
		margin: 2rem;

		@media only screen and (max-width: 770px) and (max-height: 570px) {
			display: none;
		}

		img{
			height: clamp(120px, 25vh, 170px);
			width: auto;
			max-width: unset;
		}
	}

    .alerta-erro {
        margin-top: 5px;
        border-radius: 2rem;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        padding: 0.5rem 1.25rem;

        .close{
            padding: 0.5rem 1.25rem;
        }

        p {
            margin: 0;
            
        }
    }
    
    .bg-image {
        // background-image: url('https://source.unsplash.com/WEQbe2jBg40/600x1200');
        background-size: cover;
        background-position: center;
        align-items: center;
        justify-content: center;

        img {
            max-width: 80%;
            width: 100%;
        }
    }
    
    .login-heading {
		font-weight: 400;
    }
    
	.btn{
        box-shadow: 1px 1px 3px #f3b874 !important;
    }

	.form-control{
    	background: #fdf9f3;
    	border-color: #fdd7ae;

		&:focus{
			color: #495057;
    		background-color: #fdf8f2;
    		border-color: #ffc383;
			outline: 0;
    		box-shadow: 0 0 0 0.2rem #fbe8d3;
		}
	}

	a, a:not([href]){
        color: #6b1f2b;
        cursor: pointer;

        &:hover{
            text-decoration: underline;
        }
    }
    
    .btn-login {
        font-size: 0.9rem;
        letter-spacing: 0.05rem;
        padding: 0.5rem 0.75rem;
        border-radius: 2rem;
    }
    
    .form-label-group {
        position: relative;
        margin-bottom: 1rem;
    }
    
    .form-label-group > .form-control,
    .form-label-group>label {
        padding: var(--input-padding-y) var(--input-padding-x);
        height: auto;
        border-radius: 2rem;

        &.input-erro {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }
    
    .form-label-group>label {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        margin-bottom: 0;
        /* Override default `<label>` margin */
        line-height: 1.5;
        color: #495057;
        cursor: text;
        /* Match the input under the label */
        border: 1px solid transparent;
        border-radius: .25rem;
        transition: all .1s ease-in-out;
    }
    
    .form-label-group input::-webkit-input-placeholder {
        color: transparent;
    }
    
    .form-label-group input:-ms-input-placeholder {
        color: transparent;
    }
    
    .form-label-group input::-ms-input-placeholder {
        color: transparent;
    }
    
    .form-label-group input::-moz-placeholder {
        color: transparent;
    }
    
    .form-label-group input::placeholder {
        color: transparent;
    }
    
    .form-label-group input:not(:placeholder-shown) {
        padding-top: calc(var(--input-padding-y) + var(--input-padding-y) * (2 / 3));
        padding-bottom: calc(var(--input-padding-y) / 3);
    }
    
    .form-label-group input:not(:placeholder-shown)~label {
        padding-top: calc(var(--input-padding-y) / 3);
        padding-bottom: calc(var(--input-padding-y) / 3);
        font-size: 12px;
        color: #777;
    }


    .desenvolvido {
        color: white;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin-bottom: -1;;

        p {
            margin: 0;
            margin-right: 5px;
        }

        img {
            height: 60px;
        }
    }

    
}