.pedreira {

}


@media (max-width: 768px) {
    .pedreira {

        .lista {
            display: flex;
            flex-direction: column;

            .logo {
                position: relative;
                margin: 0;
                padding: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 10px;

                img {
                    height: 100px;
                }
            }

            .tabela {
                padding-top: 0 !important;
            }
        }
    }
}