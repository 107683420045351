.paginacao {
    display: flex;

    .paginas {
        text-align: center;
        flex-grow: 1;
        .botao {
            margin-right: 10px;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .botao.ativo {
        background:#d5f1a8;
        color: black;
    }

    .espaco {
        margin-right: 10px;
    }

    .totalRegistros {
        display: flex;
        align-items: center;
    }
}