.container {
    display: flex;

    .imagem {
        width: 38%;
        object-fit: contain;
    }

    .info {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        
        .descricao {
            white-space: normal;
            word-break: break-all;
            flex-grow: 1;
        }

        .opcoes {
            text-align: right;
        }
    }
}