.painel-sidebar {
    position: absolute;
    border-radius: 30px;
    width: var(--width-sidebar);
    display: block;
    color: rgb(80, 80, 80);
    font-weight: 200;
    background-size: cover;
    background-position: center center;
    max-height: 100%;
    height: 100%;
    -webkit-transition-property: top, bottom;
    transition-property: top, bottom;
    -webkit-transition-duration: .2s, .2s;
    transition-duration: .2s, .2s;
    -webkit-transition-timing-function: linear, linear;
    transition-timing-function: linear, linear;
    -webkit-overflow-scrolling: touch;
    transition: width 0.3s;
    height: -webkit-fill-available;
    max-height: none;
    display: flex;
    background: white;
    z-index: 4;
    filter: drop-shadow(0px 0px 2px #e0e0e0db);
    background-color: #ffffff9c;

    &.mobile {
        // display: none;
        // width: 0;
        background-color: #ffffffd1;

        .itens {
            display: none;
        }
    }

    &.mobile:not(.encolher) {
        // display: block;
        // width: 100%;
        // z-index: 1;
        // background: #eee;
        // margin: 0;
        // left: 0;
        // right: 0;
        // bottom: 0;
        // top: 50px;
        // min-height: 100vh;
        // border-radius: 0 !important;
        // padding: 0;
        // position: fixed;

        
        .itens {
            display: flex;
            flex-direction: column;
        }

    }

    &.encolher {
        // --width-sidebar: 90px;

        .itens {
            
            .logo {
                // min-height: 60.6px;

                button {

                    .icone {
                        // font-size: 21px;
                    }
                }

                .logo-imagem {
                    display: none;
                }
            }

            .nav {
                li {
                    // overflow: hidden;
                    .nav-link {
                        // padding: 0.25rem;
                        overflow: hidden;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        
                        .icone {
                            // font-size: 21px;
                            margin-right: 0px;
                        }

                        > p {
                            display: none;
                            opacity: 0;
                        }
                    }
                }
            }

            .alternar-menu{
                .seta{
                    margin-left: 0;
                }

            }
        }
    }

    /*
    &:before {
        display: block;
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        // z-index: 2;
        opacity: .33;
        // background: #00546b;
        border-radius: 0.25rem;
    }

    &:after {
        display: block;
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        // background: linear-gradient(to bottom, #373737 0%, #373737 100%);
        background-size: 150% 150%;
        opacity: .77;
        // z-index: 3;
        border-radius: 0.25rem;
    }
    */

    .itens {
        position: relative;
        // max-height: calc(100vh - 75px);
        // min-height: 100%;
        // overflow: auto;
        width: var(--width-sidebar);
        display: flex;
        flex-direction: column;
        
        .logo {
            position: relative;
            z-index: 4;
            padding: 10px 15px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.2);
            display: flex;
            align-items: center;
            justify-content: center;        
            height: 60.6px;

            border-bottom: 1px solid #b2b2b2;
            height: 50.6px;
            border-bottom: 1px solid #ececec;
            
            img{
                filter: drop-shadow(0px 0px 1px #6363639e);
            }

            button {
                background: none;
                border:none;

                &:focus {
                    outline: 0;
                }

                .icone {
                    color: white;
                    color: rgb(80, 80, 80);
                }
            }

            .texto-simples {
                text-transform: uppercase;
                padding: 5px 0px;
                display: block;
                font-size: 18px;
                color: #FFFFFF;
                color: rgb(80, 80, 80);
                text-align: center;
                font-weight: 400;
                line-height: 30px;
                text-decoration: none;
                flex-grow: 1;
            }

            .logo-imagem {
                flex-grow: 1;
                text-align: center;
                
                img {
                    width: 70%;
                    height: auto;
                    vertical-align: middle;
                    max-height: 100%;
                }

            }
        }

        .nav {
            margin-bottom: 15px;
            float: none;
            display: inline-block;
            // margin-left: -60px;
            width: 100%;
            flex-grow: 1;

            &:last-child {
                margin-bottom: 0;
            }

            li {

                &.active {

                    .nav-link {
                        color: #FFFFFF;
                        opacity: 1;
                        background: rgba(255, 255, 255, 0.23);
                    }
                }

                .nav-link {
                    // transition: all 20ms ease-in;
                    text-transform: uppercase;
                    line-height: 30px;
                    font-size: 12px;
                    font-weight: 600;
                    color: #FFFFFF;
                    margin: 15px 15px;
                    opacity: .86;
                    border-radius: 4px;
                    display: block;
                    padding: 5px;
                    display: flex;
                    align-items: center;
                    // padding: 7px 7px;
                    // transition: width 0.1s;
                    height: 48px;
                    position: relative;

                    &:hover {
                        // background: rgba(124, 124, 124, 0.062);
                        // opacity: 1;

                        .icone {
                            // background: rgb(243, 243, 243);
                            // border-radius: 3px;
                            // overflow: visible;
                            fill: black;
                            color: #a3a3a3 !important;

                        }

                        p {
                            color: black;
                        }
                    }

                    .icone {
                        display: inline-block;
                        font: normal normal normal 14px/1 'nucleo-icons';
                        font-size: inherit;
                        speak: none;
                        text-transform: none;
                        -webkit-font-smoothing: antialiased;
                        font-size: 20px;
                        margin-right: 15px;
                        width: 20px;
                        text-align: center;
                        vertical-align: middle;
                        float: left;
                        color: #707070;
                        // border: 2px solid #ececec;
                        
                        height: 35px;
                        width: 35px;
                        // padding: 7px;
                        // border-radius: 25px;
                        // background: #f9f8f8;
                    }

                    p {
                        margin: 0;
                        line-height: 31px;
                        font-size: 15px;
                        font-weight: 600;
                        font-weight: bold;
                        text-transform: uppercase;
                        display: inline-flex;
                        line-height: 20px;
                        text-transform: capitalize;
                        // transition: display 0.3s;
                        opacity: 1;
                        // transition: opacity 0.3s linear;
                        line-height: 14px;
                        display: block;
                        color: rgb(102, 102, 102);
                        font-weight: lighter;
                    }
                }
            }
        }
        
        .alternar-menu{
            display: flex;
            align-items: center;
            border-top: 1px solid #b2b2b2;
            flex-direction: row;
            padding: 12px 25px;
            justify-content: flex-end;
            -webkit-box-pack: end;
            cursor: pointer;
            font-weight: 300;
            font-size: 15px;
            color: #666666;

            .seta{
                margin-left: 4px;
                width: 24px;
                text-align: center;
                font-weight: 100;
                font-size: 17px;
                padding: 0;
                margin-bottom: -4px;
            }
        }
    }

    .itens-fundo {
        position: absolute;
        z-index: 1;
        height: 100%;
        width: 100%;
        display: block;
        top: 0;
        left: 0;
        background-size: cover;
        background-position: center center;
        opacity: .77;
        
    }
}


@media only screen and (max-width : 600px) {
    .painel-sidebar {
        box-shadow: 1px 1px 4px #73737361;
        
        &.encolher{
            box-shadow: unset;
        }
    }
}