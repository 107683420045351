.campoPesquisa{
    border-radius: 2px;
    border: 0 !important;
    box-shadow: inset 0px 0px 3px #b7a99a99;
    height: 35px;
    border-radius: 3px !important;
    pointer-events: none;

    input{
        border: 0;
        box-shadow: none !important;
        outline: 0;
        background: none !important;
        height: auto;
        pointer-events: auto;
        padding-left: 0;
    }

    &:hover{
        box-shadow: inset 0px 0px 4px #d0c4b8c9;
    }

    &:focus, &:active, &:focus-within{
        box-shadow: inset 0px -1px 3px #bbaea280, inset 0px 1px 2px #cccccc73 !important;
    }
}

.botao{
    box-shadow: -1px 1px 2px #ebe7e3 !important;

    &:hover{
        background: #f0f0f0b8 !important;
    }
}

.icone{
    width: 35px;
    font-size: 16px;
    color: #bbbaba;
    text-align: center;
}