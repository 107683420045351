.botaoSair {
    background: none;
    border: none;
    display: flex;
    align-items: center;
    color: #888888;

    &:focus {
        outline: 0;
    }

    &:hover {

        color: #505050;
    }

    .icone {
        margin-left: 5px;
        font-size: 11px;
    }
}

.navbarBrand {
    display: flex;
    align-items: center;
    border-right: 1px solid #c9c9c9;
    padding-right: 10px;

    .info {
        // font-size: 13px !important;
        color: #505050;
        text-align: right;
        
        span {
            display: block;
    
            &.cargo {
                font-size: 10px;
                color: #9f9f9f;
            }
    
            &.nome {
    
                &::first-letter {
                    // font-weight: bold;
                    // color: red;
                    text-transform: uppercase;
                  }
            }
        }
    }

    .foto {
        height: 35px;
        width: 35px;
        object-fit: cover;
        margin-left: 5px;
        border-radius: 20px;
        // border: 2px solid rgb(192, 192, 192);
        box-shadow: 0 0 2px grey;
        border-radius: 35px;
    }

    .iconeUsuario {
        margin-left: 5px;
        font-size: 35px;
        color: rgb(175, 175, 175);
        border: 2px solid rgb(192, 192, 192);
        border-radius: 35px;
    }
}

.link {


    &:hover {
        
        .navLink {
            color: #7d7d7d !important;
        }

        
    }

    .linkButton {
        padding: 0;
        border: 0;
        background: none;
        box-shadow: none !important;
        outline: 0;

        &:hover {
            background: none;
        }

        &:active, &:focus {
            background: none !important;
            color:#878787 !important;
        }


        &.botaoPonto {
            color: rgb(5, 76, 209) !important;

            &:hover {
                color: rgb(26, 81, 182) !important;
            }

            &:active {
                color: rgb(26, 37, 138) !important;
            }
        }
    }

    &.fazerBatida {

        .linkButton {

            &.botaoPonto {
                background: yellow;
                color: rgb(5, 209, 5) !important;
    
                &:hover {
                    color: rgb(26, 182, 26) !important;
                }
    
                &:active {
                    color: rgb(26, 138, 26) !important;
                }
            }

            &:disabled {
                background: grey;
            }

        }
    }

    
}
