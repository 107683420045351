.input{
    border: 0 !important;
    resize: none;
    box-shadow: inset 0px 0px 3px #9e9e9e36;

    &:hover{
        box-shadow: inset 0px 0px 3px #b1afaf61;
    }

    &:focus, &:active{
        box-shadow: inset 0px 1px 2px #d8d8d861, inset 0px -1px 3px #d4d2d261 !important;
    }

    &[readonly]{
        background: none !important;
        box-shadow: inset 0px 0px 3px #b8b8b836;
        cursor: default;
    }
}

.linhaSalvar{
    
    button{
        border-radius: 3px;
        border: 0 !important;
        outline: 0;
        font-weight: 500 !important;
        padding: 4px 14px;
        margin-left: 10px;
    }
}

.alerta{
    font-size: 12px;
    padding: 5px 10px !important;
    border-radius: 5px;

    button{
        padding: 4px 2px !important;
        font-size: 20px;
    }

    p{
        margin: 0;
    }
}