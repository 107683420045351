.divCentro{
    align-self: center;
    max-width: 1180px;
    margin-right: auto;
    margin-left: auto;
}

.sombraCard{
    box-shadow: rgba(0, 0, 0, 0.05) 0px 2px 15px;
    height: unset;
}

.cardAtalho{
    cursor: pointer;
    background-color: #ffffff80 !important;

    a{
        font-size: 18px;
        text-decoration: none;
        color: black;

        &:hover{
            text-decoration: none;
            color: black;
        }
    }

    &:hover{
        box-shadow: 0px 3px 4px #ff66008c;

        a{
            color: #FF6600;
        }
    }

    img{
        max-width: 100%;
    }
}