.painel-pagina {
    --width-sidebar: 290px;
    position: relative;
    top: 0;
    height: 100vh;

    &.menu-encolher {
        --width-sidebar: 75px;

        &.menu-mobile {
            --width-sidebar: 0;
        }
    }

    &.menu-mobile{
        --width-sidebar: 100vw;
    }

    .painel-pagina-corpo {
        background: rgba(203, 203, 210, 0.15);
        position: relative;
        float: right;
        transition: width 2s;
        width: calc(100% - var(--width-sidebar));
        min-height: 100%;
        overflow: auto;
        max-height: 100%;
        height: 100%;
        transition-property: top, bottom;
        transition-duration: .2s, .2s;
        transition-timing-function: linear, linear;
        transition: width 0.3s;

        > .content {
            padding: 15px;
            min-height: calc(100% - 50px);
        }
        
        .formulario{
            .form-label{
                margin-bottom: 2px;
            }

            .col, [class*="col-"]{
                margin-bottom: 1rem !important;
            }

            input{
                border: 0;
                border-bottom: 1px solid #cccccc;
                border-radius: 0;
                box-shadow: none;
                outline: 0;
        
                &:hover{    
                    border-bottom: 1px solid #a0897f;
                }
        
                &:focus, &:active{    
                    border-bottom: 1px solid #67615e;
                }

                &[readonly] {
                    background: none;
                    border-bottom: none;
                    cursor: default;
                }
        
            }

            .select{
                border: 0;
                border-radius: 0;
                box-shadow: none;
                outline: 0;

                .select__control{
                    border: 0;
                    border-radius: 0;
                    box-shadow: none;
                    outline: 0;
                    border-bottom: 1px solid #cccccc;

                    &:hover{
                        border-bottom: 1px solid #8d7d76 !important;
                    }
        
                    &:focus, &:active, &.select__control--is-focused{
                        border-bottom: 1px solid #67615e;
                    }

                    
                    &.select__control--is-disabled {
                        background: none;
                        border-bottom: none;
                        cursor: default;

                        .select__indicators {
                            display: none;
                        }
                    }
                }
            }

            .textarea{
                resize: none;
                border: 1px solid #cccccc;
                
                &:hover{    
                    border: 1px solid #ab9f9ac7;
                }
        
                &:focus, &:active{    
                    border: 1px solid #67615e9e;
                }

                &[readonly] {
                    background: none;
                    // border-bottom: none;
                    cursor: default;
                }
            }
        }
    }

    .painel-mobile {
        
        // width: calc(100% - var(--width-sidebar));
        width: 100%;
    }
}

.modal-personalizado{
    .modal-content{
        border: 0;
        border-radius: 2px;
    }
}

@media only screen and (max-width : 600px) {
    .painel-pagina {
        .painel-pagina-corpo {
            width: calc(100% - min(var(--width-sidebar), 75px));

            &.painel-mobile{
                width: 100%;
            }
        }
    }
}

.modal-imagem{
    background: rgba(78, 78, 78, 0.71);

    .modal-content{
        background: #fffffff5;
    }
}