.corpo{
    box-shadow: inset 0px 0px 4px #f7f7f8;
    background: #f7f7f729;
    border-radius: 3px;
}

.tituloCard{
    font-size: 1.4rem;
    margin-bottom: 0;
    font-weight: 300;
}
